<template>
  <div id="game-tally">
    <div @click.prevent="isOpen = !isOpen">
      <span>Tally</span>
    </div>
    <div v-show="isOpen">
      <p v-html="gameTally"></p>
    </div>
  </div>
</template>

<script>
import calendarMixin from '@/mixins/calendarMixin.js';

export default {
  name: 'GameTally',
  mixins: [calendarMixin],
  props: {
    events: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    isOpen: false
  }),
  computed: {
    gameTally() {
      let result = {};

      for (let event of this.events) {
        if (!event.deleted) {
          result[event.homeTeam] = (result[event.homeTeam] || 0) + 1;
          result[event.awayTeam] = (result[event.awayTeam] || 0) + 1;
        }
      }

      return Object.keys(result).map(
        team => {
          let teamDisplayName = this.formatTeamName(team);
          return teamDisplayName == 'Senators' ?
            `<span>${result[team]} ${teamDisplayName}</span>` :
            `${result[team]} ${teamDisplayName}`;
        }).sort().join(' / ');
    }
  }
}
</script>

<style lang="scss">
#game-tally {
  width: 668px;

  > div:first-of-type {
    border-radius: 5px 5px 0 0;
    background-color: #E7F2FA;
    width: 100%;
    height: 46px;
    padding: 0 18px;
    margin: 30px 0 0 0;
    align-items: center;
    display: flex;
    position: relative;
    cursor: pointer;

    &::before {
      border-radius: 5px 0 0 0;
      background-color: $blue;
      width: 6px;
      height: 100%;
      top: 0;
      left: 0;
      content: '';
      position: absolute;
    }

    &::after {
      background: url('@/assets/dropdown.svg') no-repeat;
      background-size: 100%;
      width: 7px;
      height: 4px;
      top: 0;
      right: 21px;
      bottom: 0;
      margin: auto;
      content: '';
      position: absolute;
    }

    > span {
      font-size: 16px;
      letter-spacing: 0.05em;
      display: block;
    }
  }

  > div:nth-of-type(2) {
    border-radius: 0 0 5px 5px;
    background-color: #F3F4F7;
    font-size: 16px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0.05em;
    width: 100%;
    transition: max-height 1.0s ease;
    padding: 10px 18px;
    margin-top: 8px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;

    p {
      margin: 0;

      span {
        color: $orange;
      }
    }
  }
}
</style>
