<template>
  <section id="teams">
    <h1>Choose your teams</h1>
    <div id="teams-container">
      <div>
        <div v-for="team in teams" v-bind:key="team.name">
          <ItemCheckbox v-model="team.enabled" :class="{orange: team.fixed}">{{ team.name }}</ItemCheckbox>
        </div>
      </div>
      <p><a href="#" @click.prevent="showAddTeamPrompt">Add Team</a></p>
    </div>
    <q-dialog v-model="newTeamPrompt" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">Team Name</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input dense v-model="newTeamName" ref="newTeamName" autofocus
            :rules="newTeamRules"
            @keyup.enter="newTeamPrompt = false" />
        </q-card-section>

        <q-card-actions class="text-primary">
          <q-btn flat label="Cancel" v-close-popup />
          <q-btn flat label="Add Team" @click.prevent="addTeam"/>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <nav id="navigation">
      <button id="back" @click.prevent="goBack">Back</button>
      <button id="next" @click.prevent="goNext">Next step</button>
    </nav>
  </section>
</template>

<script>
import ItemCheckbox from './ItemCheckbox.vue';

export default {
  name: 'HockeyTeams',
  components: {ItemCheckbox},
  data: () => ({
    newTeamName: null,
    newTeamPrompt: false,
    newTeamRules: [value => !!value],
    teams: {}
  }),
  mounted() {
    this.teams = [...this.$store.state.teams];
  },
  methods: {
    showAddTeamPrompt() {
      this.newTeamName = null;
      this.newTeamPrompt = true;
    },
    addTeam() {
      let newTeamName = this.$refs.newTeamName;
      
      newTeamName.validate();

      if (!newTeamName.hasError) {
        this.teams.push({name: this.newTeamName.toUpperCase(), enabled: true, fixed: false});
        this.$store.commit('setTeams', this.teams);

        this.newTeamPrompt = false;
      }
    },
    goBack() {
      this.$router.push({path: '/season'});
    },
    goNext() {
      this.$store.commit('setTeams', this.teams);
      this.$router.push({path: '/schedule'});
    }
  }
}
</script>

<style lang="scss">
section#teams {
  > div#teams-container {
    border: 2px solid $borderBlue;
    border-radius: 13px;
    background-color: white;
    max-width: 956px;
    padding: 24px 48px;
    margin: 0 40px;
    box-sizing: border-box;
    flex-direction: column;
    display: inline-flex;

    &.fixed {
      border: 2px solid $orange;
    }

    > div {
      flex-wrap: wrap;
      display: flex;

      > * {
        margin: 10px 16px;
      }
    }

    > p {
      margin: 30px 0 24px 44px;
    }
  }
}
</style>