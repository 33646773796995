import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import { DateTime } from 'luxon';

const vuexLocal = new VuexPersistence({
  storage: localStorage,
  restoreState: (key, storage) => {
    let state = JSON.parse(storage.getItem(key));

    if (!state) return state;

    state.startDate = state.startDate ? new Date(state.startDate) : 
      DateTime.now().startOf('year').plus({month: 8, week: 1}).toJSDate();
    state.endDate = state.endDate ? new Date(state.endDate) : 
      DateTime.now().startOf('year').plus({year: 1, month: 3, week: 1}).toJSDate();

    state.calendarHolidays = (state.calendarHolidays || []).map((holiday) => {
      return {...holiday, date: DateTime.fromISO(holiday.date)};
    });

    state.holidays = (state.holidays || []).map((holiday) => {
      return {...holiday, date: DateTime.fromISO(holiday.date)};
    });

    state.gameDates = (state.gameDates || []).map((gameDate) => {
      return {...gameDate, date: DateTime.fromISO(gameDate.date)};
    });

    state.gameDatesFinal = (state.gameDatesFinal || []).map((gameDate) => {
      return {...gameDate, date: DateTime.fromISO(gameDate.date)};
    });

    state.gameSchedule = (state.gameSchedule || []).map((gameDate) => {
      return {...gameDate, gameTime: DateTime.fromISO(gameDate.gameTime)};
    });

    state.gameScheduleFinal = (state.gameScheduleFinal || []).map((gameDate) => {
      return {...gameDate, gameTime: DateTime.fromISO(gameDate.gameTime)};
    });

    return state;
  },
  saveState: (key, state, storage) => {
    storage.setItem(key, JSON.stringify(state));
  }
});

const store = new Vuex.Store({
  state: {
    currentStep: 0,
    currentRoute: '/',
    gameDates: [],
    gameDatesFinal: [],
    gameSchedule: [],
    gameScheduleFinal: [],
    randomLevel: 2,
    startDate: DateTime.now().startOf('year').plus({month: 8, week: 1}).toJSDate(),
    endDate: DateTime.now().startOf('year').plus({year: 1, month: 3, week: 1}).toJSDate(),
    teams: [
      {name: 'LEAFS', enabled: true, fixed: false},
      {name: 'HAWKS', enabled: true, fixed: false},
      {name: 'CANADIENS', enabled: true, fixed: false},
      {name: 'BRUINS', enabled: true, fixed: false},
      {name: 'FLYERS', enabled: true, fixed: false},
      {name: 'RANGERS', enabled: true, fixed: false},
      {name: 'WINGS', enabled: true, fixed: false},
      {name: 'STARS', enabled: true, fixed: false},
      {name: 'SENATORS', enabled: true, fixed: true}
    ],
    weekSchedule: {
      'Tuesday': [
        {fixedMatch: true, hour: 19, minute: 45},
        {fixedMatch: false, hour: 21, minute: 30}],
      'Wednesday': [
        {fixedMatch: false, hour: 19, minute: 45},
        {fixedMatch: false, hour: 21, minute: 30}],
      'Friday': [
        {fixedMatch: false, hour: 19, minute: 45},
        {fixedMatch: false, hour: 21, minute: 30}],
      'Sunday': [
        {fixedMatch: true, hour: 16, minute: 15, biweekly: false},
        {fixedMatch: false, hour: 18, minute: 0},
        {fixedMatch: false, hour: 19, minute: 45},
        {fixedMatch: false, hour: 21, minute: 30}]
    },
    calendarHolidays: [],
    holidays: []
  },
  getters: {
    getSelectedHolidays(state) {
      return state.holidays.filter(item => item.selected);
    }
  },
  mutations: {
    setCurrentStep(state, step) {
      state.currentStep = step;
    },
    setCurrentRoute(state, route) {
      state.currentRoute = route;
    },
    setTeams(state, teams) {
      state.teams = teams;
    },
    setDateRange(state, payload) {
      state.startDate = payload.startDate;
      state.endDate = payload.endDate;
    },
    setGameDates(state, dateList) {
      state.gameDates = dateList;
    },
    setGameDatesFinal(state, dateList) {
      state.gameDatesFinal = dateList;
    },
    setWeekSchedule(state, weekSchedule) {
      state.weekSchedule = {...weekSchedule};
    },
    setCalendarHolidays(state, calendarHolidays) {
      state.calendarHolidays = calendarHolidays;
    },
    setHolidays(state, holidays) {
      state.holidays = [...holidays];
    },
    setRandomLevel(state, randomLevel) {
      state.randomLevel = randomLevel;
    },
    setGameSchedule(state, schedule) {
      state.gameSchedule = schedule;
    },
    setGameScheduleFinal(state, schedule) {
      state.gameScheduleFinal = schedule;
    }
  },
  plugins: [vuexLocal.plugin]
});

export default store;
