<template>
  <section id="calendar">
    <h1>Review your rink schedule</h1>
    <div>
      <div id="holidays">
        <ul>
          <li v-for="(holiday, index) in holidays" v-bind:key="index">
            <a href="#" @click.prevent="gotoDate(holiday.date)">{{ holiday.title }}</a>
          </li>
        </ul>
      </div>
      <div>
        <h2>{{ monthAndYear }}</h2>
        <q-calendar-month
          ref="calendar"
          v-model="selectedDate"
          animated
          :day-min-height="100"
        >
          <template #day="{ scope: { timestamp } }">
            <template v-for="holiday in getHolidays(timestamp.date)" :key="holiday.id">
              <div class="holiday rounded-border">
                <div class="title q-calendar__ellipsis">
                  {{ holiday.title }}
                  <q-tooltip>{{ holiday.title }}</q-tooltip>
                </div>
              </div>
            </template>
            <template v-for="event in getEvents(timestamp.date)" :key="event.id">
              <div class="game-event rounded-border" :class="{deleted: event.deleted}">
                <div class="title q-calendar__ellipsis">
                  {{ formatGameTime(event) }}
                  <q-tooltip>{{ formatDateRange(event) }}</q-tooltip>
                </div>
                <q-btn v-if="!event.deleted"
                  flat class="absolute-right" size="xs" padding="xs" color="tba-blue" icon="close"
                  @click="eventDelete(event)" />
                <q-btn v-if="event.deleted"
                  flat class="absolute-right" size="xs" padding="xs" color="grey" icon="refresh"
                  @click="eventDelete(event, true)" />
              </div>
            </template>
          </template>
        </q-calendar-month>
      </div>
    </div>
    <nav id="navigation">
      <button id="back" @click.prevent="goBack">Back</button>
      <button id="next" @click.prevent="goNext">Next step</button>
    </nav>
  </section>
</template>

<script>
/* eslint-disable */

import calendarMixin from '@/mixins/calendarMixin.js';
import dragDropMixin from '@/mixins/dragDropMixin.js';

import { DateTime } from 'luxon';
import { QCalendarMonth } from '@quasar/quasar-ui-qcalendar/src/index.js';

import '@quasar/quasar-ui-qcalendar/src/QCalendarVariables.sass';
import '@quasar/quasar-ui-qcalendar/src/QCalendarTransitions.sass';
import '@quasar/quasar-ui-qcalendar/src/QCalendarMonth.sass';

export default {
  name: 'SchedulerReview',
  components: {QCalendarMonth},
  mixins: [calendarMixin, dragDropMixin],
  data: () => ({
    selectedDate: DateTime.now().toFormat('yyyy-MM-dd'),
    events: [],
    holidays: []
  }),
  computed: {
    eventsSelectedDate() {
      let regex = /\-[0-9]{2}$/;
      return this.events.filter(
        event => event.date.replace(regex, '') == this.selectedDate.replace(regex, ''));
    },
    monthAndYear() {
      return DateTime.fromISO(this.selectedDate).toFormat('LLLL yyyy');
    }
  },
  mounted() {
    let holidays = this.$store.state.holidays,
      gameDates = this.$store.state.gameDatesFinal.length ?
        this.$store.state.gameDatesFinal :
        this.$store.state.gameDates.filter(gameDate => !gameDate.holiday);

    this.selectedDate = gameDates.length ?
      gameDates[0].date.toFormat('yyyy-MM-dd') : this.selectedDate;

    for (let [index, holiday] of Object.entries(holidays)) {
      this.holidays.push({
        id: index + 1,
        title: holiday.name,
        date: holiday.date.toFormat('yyyy-MM-dd'),
        bgcolor: 'red'
      });
    }

    for (let [index, gameDate] of Object.entries(gameDates)) {
      this.events.push({
        id: index + 1,
        title: 'Game',
        fixedMatch: gameDate.fixedMatch,
        date: gameDate.date.toFormat('yyyy-MM-dd'),
        time: gameDate.date.toFormat('HH:mm'),
        duration: 90,
        bgcolor: 'blue',
        deleted: !gameDate.selected
      });
    }
  },
  methods: {
    getEvents(date) {
      return this.eventsSelectedDate.filter(event => event.date == date);
    },
    eventDelete(event, restore = false) {
      let eventIndex = this.events.map(item => item.id).indexOf(event.id);

      this.events[eventIndex].deleted = !restore;
    },
    gotoDate(dateString) {
      this.selectedDate = dateString;
    },
    goBack() {
      this.$router.push({path: '/holidays'});
    },
    goNext() {
      let gameDatesFinal = this.events.map(
        event => { 
          return {
            fixedMatch: event.fixedMatch,
            date: DateTime.fromSQL(`${event.date} ${event.time}`),
            selected: !event.deleted
          };
        });

      this.$store.commit('setGameDatesFinal', gameDatesFinal);

      this.$router.push({path: '/pattern'});
    }
  }
}
</script>

<style lang="scss" scoped>
#calendar {
  > div {
    display: flex;

    > div {
      &:first-of-type {
        max-width: 364px;

        > button {
          width: 268px;
          min-height: 69px;
        }
      }

      &:last-of-type {
        width: 830px;
      }
    }
  }

  .q-calendar {
    border: 1px solid $grey;
  }
}

#holidays {
  border: 1px solid $borderBlue;
  border-radius: 5px;
  background-color: white;
  width: 200px;
  padding: 26px 28px;
  margin-top: 38px;
  margin-right: 20px;
  box-sizing: border-box;

  > ul {
    padding: 0;
    margin: 0;
    list-style: none;
    flex-direction: column;
    display: flex;

    > li {
      &:not(:last-of-type) {
        margin-bottom: 32px;
      }

      > a {
        font-size: 14px;
        font-weight: 400;
        text-decoration: none;
        color: $darkerBlue;
      }
    }
  }
}

.holiday,
.game-event {
  border-radius: 5px;
  position: relative;
  font-size: 12px;
  font-weight: normal;
  width: 95%;
  height: 31px;
  margin: 1px 0 0 0;
  box-sizing: border-box;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  position: relative;

  button span {
    color: $blue;
  }
}

.holiday {
  background-color: #F26522;
  font-style: italic;
  color: white;
  padding: 1px 10px;
  width: 100%;
}

.game-event {
  background-color: rgba(0, 126, 218, 0.1);
  text-transform: lowercase;
  color: #002F5B;
  padding: 1px 10px 1px 21px;

  &::before {
    border: none;
    border-radius: 50%;
    background-color: $blue;
    width: 7px;
    height: 7px;
    top: 0;
    bottom: 0;
    left: 7px;
    margin: auto 0;
    content: ' ';
    position: absolute;
  }

  &.deleted {
    background-color: rgba(213, 213, 213, 0.5);
    text-decoration: line-through;
    color: #002F5B;

    &::before {
      background-color: rgba(0, 47, 91, 0.5);
    }
  }
}
</style>