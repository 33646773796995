<template>
  <section id="pattern">
    <h1>Randomize your schedule</h1>
    <div id="pattern-selection">
      <span>Repeating Pattern</span>
      <div>
        <q-slider v-model="randomLevel" :min="1" :max="4" :step="1" snap />
      </div>
      <span>
        Randomization<br />
        (up to 3 back to back games)
      </span>
    </div>
    <nav id="navigation">
      <button id="back" @click.prevent="goBack">Back</button>
      <button id="next" @click.prevent="goNext">Next step</button>
    </nav>
  </section>
</template>

<script>
import SchedulerService from '../services/SchedulerService.js';
import { mapGetters } from 'vuex';

export default {
  name: 'SchedulerPattern',
  data: () => ({
    randomLevel: 2
  }),
  computed: {
    ...mapGetters(['getMatchedTeams'])
  },
  mounted() {
    this.randomLevel = this.$store.state.randomLevel;
  },
  methods: {
    goBack() {
      this.$router.push({path: '/review'});
    },
    goNext() {
      let gameDates = this.$store.state.gameDatesFinal.filter(gameDate => gameDate.selected),
        teams = this.$store.state.teams.filter(team => team.enabled);

      this.$store.commit(
        'setGameSchedule', SchedulerService.createSchedule(gameDates, teams, this.randomLevel));
      this.$store.commit('setRandomLevel', this.randomLevel);

      this.$router.push({path: '/finalize'});
    }
  }
}
</script>

<style lang="scss">
#pattern-selection {
  border: 2px solid $borderBlue;
  border-radius: 13px;
  background-color: white;
  padding: 94px 37px;
  width: 956px;
  box-sizing: border-box;
  justify-content: space-around;
  align-items: flex-start;
  display: flex;

  > div {
    width: 400px;
    padding-top: 14px;
  }

  > span {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: $darkerBlue;
    width: 206px;

    &::first-line {
      font-size: 21px;
      line-height: 54px;
    }
  }
}
</style>