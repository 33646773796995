<template>
  <section id="holidays">
    <h1>Holidays adjustment</h1>
    <div id="holidays-container">
      <div>
        <div v-for="(holiday, index) in holidays" v-bind:key="index">
          <ItemCheckbox v-model="holidays[index].selected">{{ holiday.name }}</ItemCheckbox>
        </div>
      </div>
    </div>
    <nav id="navigation">
      <button id="back" @click.prevent="goBack">Back</button>
      <button id="next" @click.prevent="goNext">Next step</button>
    </nav>
  </section>
</template>

<script>
import HolidayService from '@/services/HolidayService.js';
import ItemCheckbox from './ItemCheckbox.vue';

export default {
  name: 'HockeyHolidays',
  components: {ItemCheckbox},
  data: () => ({
    holidays: []
  }),
  mounted() {
    this.holidays = {...this.$store.state.holidays};
  },
  methods: {
    goBack() {
      this.$router.push({path: '/schedule'});
    },
    goNext() {
      let gameDates = HolidayService.applyHolidays(
        this.$store.state.holidays, this.$store.state.gameDates);

      this.$store.commit('setGameDates', gameDates);
      this.$store.commit('setGameDatesFinal', []);

      this.$router.push({path: '/review'});
    }
  }
}
</script>

<style lang="scss">
section#holidays {
  > div#holidays-container {
    border: 2px solid $borderBlue;
    border-radius: 13px;
    background-color: white;
    max-width: 956px;
    padding: 24px 48px;
    margin: 0 40px;
    box-sizing: border-box;
    flex-direction: column;
    display: inline-flex;

    > div {
      flex-wrap: wrap;
      display: flex;

      > * {
        margin: 10px 16px;
      }
    }

    > p {
      margin: 30px 0 24px 44px;
    }
  }
}
</style>