import axios from 'axios';
import { DateTime } from 'luxon';

class HolidayService {
  /**
   * Retrieve public holidays for Canada and British Columbia from the Google Calendar API.
   * @returns An array of {@code DateTime} objects.
   */
  async getCalendarHolidays() {
    let apiUrl = `https://hockey.tbadigital.com/holidays.php`,
    //let apiUrl = `/holidays.json`,
      response = await axios(apiUrl),
      holidays = ((response.data || {}).items || []).filter((item) => {
        return item.status == 'confirmed' &&
          (item.description == 'Public holiday' || /British Columbia/.test(item.description));
      }),
      result = holidays.map((item) => {
        return {name: item.summary, date: DateTime.fromSQL(item.start.date), selected: true};
      }).sort((a, b) => { return a.date > b.date ? 1 : -1 });

    return result;
  }

  /**
   * Get Super Bowl date (currently it will be the 2nd Sunday of February).
   * @param {DateTime} startDate
   * @param {DateTime} endDate
   * @returns A {@code DateTime} object containing the Super Bowl date.
   */
  getSuperBowl(startDate, endDate) {
    let year = startDate < 2 ? startDate.year : endDate.year;
    return {name: 'Super Bowl Sunday',
      date: DateTime.fromISO(`${year}-02-01`).startOf('week').plus({days: 13}),
      selected: true};
  }

  /**
   * Truncate public holidays based on user-selected date range.
   * @param {Array} calendarHolidays
   * @param {DateTime} startDate 
   * @param {DateTime} endDate 
   * @returns An array of truncated holiday objects.
   */
  getHolidays(calendarHolidays, startDate, endDate) {
    let _startDate = DateTime.fromISO(startDate), _endDate = DateTime.fromISO(endDate),
      holidays = [...calendarHolidays];

    holidays.push(this.getSuperBowl(_startDate, _endDate));

    return holidays.filter((item) => {
      return item.date.startOf('day') >= _startDate && item.date <= _endDate.startOf('day');
    }).sort((a, b) => { return a.date > b.date ? 1 : -1 });
  }

  /**
   * Apply holidays 
   * @param {*} holidays 
   * @param {*} gameDateTimes 
   */
  applyHolidays(holidays, gameDates) {
    let selectedHolidays = holidays.filter(holiday => holiday.selected).map(
      holiday => holiday.date.toFormat('yyyy-MM-dd'));

    for (let gameDate of gameDates) {
      gameDate.holiday = selectedHolidays.indexOf(gameDate.date.toFormat('yyyy-MM-dd')) != -1;
    }

    return gameDates;
  }
}

let holidayService = new HolidayService();

export default holidayService;