<template>
  <header>
    <SchedulerProgress />
    <button id="reset" @click.prevent="resetAll">Reset All</button>
  </header>
  <main id="content">
    <router-view></router-view>
  </main>
  <footer></footer>
</template>

<script>
import SchedulerProgress from '@/components/SchedulerProgress.vue';
import HolidayService from '@/services/HolidayService.js';

import '@/scss/styles.scss';

export default {
  name: 'App',
  components: {SchedulerProgress},
  mounted() {
    (async () => {
      let calendarHolidays = await HolidayService.getCalendarHolidays();
      this.$store.commit('setCalendarHolidays', calendarHolidays);
    })();
  },
  methods: {
    resetAll() {
      localStorage.clear();
      document.location.reload();
    }
  }
}
</script>

<style lang="scss">
header {
  width: 100vw;
  height: 140px;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 2;

  > button#reset {
    border: 0;
    background-color: transparent;
    font-size: 16px;
    font-weight: bold;
    text-transform: none;
    color: $lightBlue;
    padding: 0;
    right: 37px;
    bottom: 22px;
    position: absolute;
  }
}

main#content {
  margin-top: 140px;
}

footer {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: -1;

  &::after {
    background: transparent url('@/assets/logo.svg') no-repeat;
    width: 85px;
    height: 27px;
    bottom: 35px;
    left: 41px;
    content: '';
    display: inline-block;
    position: absolute;
  }
}

.text-tba-blue {
  color: $blue !important;
}
.bg-tba-blue {
  background: $blue !important;
}
</style>