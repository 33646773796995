<template>
  <section id="season">
    <h1>When does your season start and end?</h1>
    <div id="season-container">
      <div>
        <div class="date-select">
          <label for="startDate">Start Date</label>
          <q-input ref="qDateStart" filled v-model="startDate" mask="####-##-##" :rules="dateRules">
            <template v-slot:prepend>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy ref="qProxyDateStart"
                    cover transition-show="scale" transition-hide="scale">
                  <q-date v-model="startDate" mask="YYYY-MM-DD"
                      @update:model-value="$refs.qProxyDateStart.hide()">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </div>
        <div class="date-select">
          <label for="endDate">End Date</label>
          <q-input ref="qDateEnd" filled v-model="endDate" mask="####-##-##" :rules="dateRules">
            <template v-slot:prepend>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy ref="qProxyDateEnd"
                    cover transition-show="scale" transition-hide="scale">
                  <q-date v-model="endDate" mask="YYYY-MM-DD"
                      @update:model-value="$refs.qProxyDateEnd.hide()">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </div>
      </div>
      <p v-if="validationError" class="red">{{ validationError }}</p>
    </div>
    <nav id="navigation">
      <button id="next" @click.prevent="goNext">Next step</button>
    </nav>
  </section>
</template>

<script>
import { DateTime } from 'luxon';

import HolidayService from '../services/HolidayService';

export default {
  name: 'HockeySeason',
  data: () => ({
    startDate: null,
    endDate: null,
    dateRules: [value => !!value && /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(value)],
    validationError: null,
  }),
  watch: {
    startDate() { this.dateSelected(); },
    endDate() { this.dateSelected(); }
  },
  mounted() {
    this.startDate = DateTime.fromJSDate(this.$store.state.startDate).toFormat('yyyy-MM-dd');
    this.endDate = DateTime.fromJSDate(this.$store.state.endDate).toFormat('yyyy-MM-dd');
  },
  methods: {
    validateDates() {
      this.validationError = false;
      this.$refs.qDateStart.validate();
      this.$refs.qDateEnd.validate();
      if (!this.startDate || !this.endDate) return false;

      if (DateTime.fromISO(this.startDate) > DateTime.fromISO(this.endDate)) {
        this.validationError = 'Start date must be before the end date.';
        return false;
      }

      return true;
    },
    dateSelected() {
      if (!this.validateDates()) return;

      this.$store.commit('setDateRange', {
        startDate: DateTime.fromISO(this.startDate).toJSDate(),
        endDate: DateTime.fromISO(this.endDate).toJSDate()
      });
    },
    goNext() {
      if (!this.validateDates()) return;

      let holidays = HolidayService.getHolidays(
        this.$store.state.calendarHolidays, this.startDate, this.endDate);
      this.$store.commit('setHolidays', holidays);

      this.$router.push({path: '/teams'});
    }
  }
}
</script>

<style lang="scss">
#season-container {
  border: 2px solid $borderBlue;
  border-radius: 20px;
  background-color: white;
  padding: 40px 100px;
  box-sizing: border-box;
  align-items: center;
  flex-direction: column;
  display: flex;

  > div {
    align-items: center;
    justify-content: center;
    display: flex;

    > div {
      width: 274px;
      max-width: 274px;
      flex-direction: column;
      display: flex;
      position: relative;

      &:not(:last-of-type) {
        margin-right: 161px;

        &::before {
          border: 2px dashed $blue;
          width: 110px;
          height: 2px;
          top: 0;
          bottom: 0;
          left: 299px;
          margin: auto;
          content: ' ';
          position: absolute;
        }
      }

      > *:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}
</style>