<template>
  <section id="calendar">
    <h1>Set weekly rink schedule</h1>
    <q-calendar-day
      v-model="selectedDate"
      view="week"
      locale="en-us"
      hoverable
      :weekdays="[1,2,3,4,5,6,0]"
      :interval-height="12"
      :interval-start="64"
      :interval-minutes="15"
      :interval-count="34"
      :drag-enter-func="onDragEnter"
      :drag-over-func="onDragOver"
      :drag-leave-func="onDragLeave"
      :drop-func="onDrop"
      @click-time="eventCreate"
    >
      <template #day-body="{ scope }">
        <template v-for="(event, index) in getEvents(scope.timestamp.date)">
          <q-badge
            v-if="event.time"
            :key="index"
            size="sm"
            class="q-pa-xs justify-center full-width"
            :class="badgeClass(event)"
            :style="badgeStyles(event, 'body', scope.timeStartPos, scope.timeDurationHeight)"
            draggable="true"
            @dragstart="onDragStart($event, event)"
          >
            <span class="ellipsis">{{ formatDateRange(event) }}</span>
            <q-btn flat class="absolute-top-right" size="xs" padding="xs" color="$blue" icon="close"
              @click="eventDelete(event)" />
          </q-badge>
        </template>
      </template>
    </q-calendar-day>
    <nav id="navigation">
      <button id="back" @click.prevent="goBack">Back</button>
      <button id="next" @click.prevent="goNext">Next step</button>
    </nav>
  </section>
</template>

<script>
/* eslint-disable */

import SchedulerService from '@/services/SchedulerService.js';
import calendarMixin from '@/mixins/calendarMixin.js';
import dragDropMixin from '@/mixins/dragDropMixin.js';

import { DateTime, Info } from 'luxon';
import { QCalendarDay } from '@quasar/quasar-ui-qcalendar/src/index.js';

import '@quasar/quasar-ui-qcalendar/src/QCalendarVariables.sass'
import '@quasar/quasar-ui-qcalendar/src/QCalendarTransitions.sass'
import '@quasar/quasar-ui-qcalendar/src/QCalendarDay.sass';

export default {
  name: 'HockeySchedule',
  components: {QCalendarDay},
  mixins: [calendarMixin, dragDropMixin],
  data: () => ({
    selectedDate: '',
    events: []
  }),
  mounted() {
    let weekSchedule = this.$store.state.weekSchedule;

    for (let [weekDay, games] of Object.entries(weekSchedule)) {
      for (let game of games) {
        let startDate = DateTime.now().startOf('week').plus(
          {days: Info.weekdays().indexOf(weekDay), hours: game.hour, minutes: game.minute});

        this.createEvent(startDate, {fixedMatch: game.fixedMatch, biweekly: game.biweekly == true});
      }
    }
  },
  methods: {
    createEventId() {
      let sortDescending = (a, b) => a > b ? -1 : 0;

      return (this.events.map(item => item.id).sort(sortDescending).shift() || 0) + 1;
    },
    createEvent(dateTime, options) {
      this.events.push({
        ...options, 
        ...{
          id: this.createEventId(),
          title: 'Game',
          date: dateTime.toFormat('yyyy-MM-dd'),
          time: dateTime.toFormat('HH:mm'),
          duration: 90,
          bgcolor: 'blue'
        }
      });
    },
    eventCreate(event) {
      let dateTime = DateTime.fromSQL(
          `${event.scope.timestamp.date} ${event.scope.timestamp.time}`),
        minute = dateTime.minute;

      dateTime = dateTime.startOf('hour').plus({minute: Math.floor(minute / 15) * 15});

      if (!this.isOverlapped(null, dateTime))
        this.createEvent(dateTime);
    },
    onDrop(e, type, scope) {
      let itemId = parseInt(e.dataTransfer.getData('ID'), 10),
        itemIndex = this.events.map(item => item.id).indexOf(itemId),
        dateTime = DateTime.fromSQL(`${scope.timestamp.date} ${scope.timestamp.time}`);

      if (!this.isOverlapped(itemId, dateTime))
        this.events[itemIndex] =
          {...this.events[itemIndex], ...{date: scope.timestamp.date, time: scope.timestamp.time}};

      this.isDragging = false;
    },
    onIntervalClass({scope}) {
      let result = {};

      if (scope.droppable) {
        let itemId = parseInt(this.dragEventId, 10),
         dateTime = DateTime.fromSQL(`${scope.timestamp.date} ${scope.timestamp.time}`);

        if (!this.isOverlapped(itemId, dateTime))
          result.allowed = true;
      }

      return {...result, ...{droppable: scope.droppable == true}};
    },
    badgeClass(event) {
      return event.fixedMatch ? 'game-event-fixed' : 'game-event';
    },
    badgeStyles(event, type, timeStartPos, timeDurationHeight) {
      let style = {'align-items': 'flex-start'};

      if (timeStartPos) {
        style.top = `${timeStartPos(event.time)}px`;
      }
      if (timeDurationHeight) {
        style.height = `${timeDurationHeight(event.duration)}px`;
      }

      return style;
    },
    goBack() {
      this.$router.push({path: '/teams'});
    },
    goNext() {
      let gameDates = [], weekSchedule = {},
        startDate = this.$store.state.startDate,
        endDate = this.$store.state.endDate,
        holidays = this.$store.getters.getSelectedHolidays;

      for (let event of this.events) {
        let gameDateTime = DateTime.fromSQL(`${event.date} ${event.time}`),
          gameTime = {
            fixedMatch: event.fixedMatch,
            biweekly: event.biweekly,
            hour: gameDateTime.hour,
            minute: gameDateTime.minute
          },
          weekDay = Info.weekdays()[gameDateTime.weekday - 1];
        
        weekSchedule[weekDay] = weekSchedule[weekDay] ?
          [...weekSchedule[weekDay], gameTime] : [gameTime];
      }

      gameDates = SchedulerService.createGameDates(startDate, endDate, weekSchedule, holidays);

      this.$store.commit('setWeekSchedule', weekSchedule);
      this.$store.commit('setGameDates', gameDates);

      this.$router.push({path: '/holidays'});
    }
  }
}
</script>

<style lang="scss">
:root {
  --calendar-border-section: 1px solid #fff !important;
}

.q-calendar {
  border: 1px solid $grey;
  background-color: transparent !important;
}

.q-calendar-day__day-interval--section {
  border-top: none !important;
}

.q-calendar__hoverable[class^=q-calendar-day__day-interval] {
  cursor: pointer;
}

.q-calendar-day__head--date {
  font-weight: normal;
}

#calendar:not(.finalize) {
  .q-calendar-day__head--date {
    display: none;
  }
}

.droppable {
  border: none;
  background-color: rgba(183, 23, 23, 0.4);

  &.allowed {
    background-color: rgba(23, 183, 73, 0.4);
  }
}
</style>

<style lang="scss" scoped>
#calendar {
  width: 1024px;
  height: 768px;
}

.game-event,
.game-event-fixed {
  border-radius: 5px;
  color: #000000;
  position: absolute !important;
  cursor: move;
}

.game-event {
  border: 2px solid $blue;
  background-color: rgba(0, 126, 218, 0.1);
}

.game-event-fixed {
  border: 2px solid $orange;
  background-color: rgba(242, 101, 34, 0.1);
}
</style>