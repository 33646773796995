import { DateTime, Duration, Interval } from 'luxon';

export default {
  methods: {
    getHolidays(date) {
      return this.holidays.filter(holiday => holiday.date == date);
    },
    getEvents(date, eventId = null) {
      return this.events.filter(
        event => eventId ? event.id != eventId && event.date == date : event.date == date);
    },
    eventDelete(event) {
      this.events = this.events.filter(item => item.id != event.id);
    },
    formatGameTime(event) {
      let startTime = DateTime.fromFormat(event.time, 'HH:mm');
      return startTime.toFormat('t');
    },
    formatDateRange(event) {
      let startTime = DateTime.fromFormat(event.time, 'HH:mm'),
        endTime = startTime.plus({minutes: event.duration});
      return `${startTime.toFormat('h:mm')} - ${endTime.toFormat('t')}`;
    },
    formatTeamName(team) {
      return team.slice(0, 1).toUpperCase() + team.toLowerCase().slice(1);
    },
    formatTeamMatch(teams) {
      return teams.map(team => this.formatTeamName(team)).join('/');
    },
    isOverlapped(eventId, dateTime, includeEvents = false) {
      let period = Duration.fromISO('PT1H30M'),
        interval = Interval.after(dateTime, period),
        dateString = dateTime.toFormat('yyyy-MM-dd'),
        existingEvents = this.getEvents(dateString, eventId).filter(item => {
          let existingEventDate = DateTime.fromSQL(`${item.date} ${item.time}`),
            existingInterval = Interval.after(existingEventDate, period)

          return interval.e > existingInterval.s && interval.s < existingInterval.e;
        }).sort((a, b) => a > b ? 1 : -1);

      return includeEvents ? existingEvents : existingEvents.length > 0;
    },
  }
}