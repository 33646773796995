<template>
  <section id="calendar" class="finalize">
    <h1>Finalize your schedule</h1>
    <div>
      <div>
        <div id="calendar-actions">
          <div>
            <button id="month-previous" @click.prevent="goPrev()"></button>
            <button id="month-next" @click.prevent="goNext()"></button>
            <h2>{{ monthAndYear }}</h2>
          </div>
          <div>
            <select id="calendar-mode" v-model="selectedMode">
              <option value="month">Monthly</option>
              <option value="day">Weekly</option>
            </select>
          </div>
        </div>
        <q-calendar
          ref="calendar"
          v-model="selectedDate"
          animated
          no-active-date
          view="week"
          :day-class="onIntervalClass"
          :interval-height="12"
          :interval-start="64"
          :interval-minutes="15"
          :interval-count="34"
          :weekdays="[1,2,3,4,5,6,0]"
          :mode="selectedMode"
          :min-weekday-length="2"
          :day-min-height="100"
          :drag-enter-func="onDragEnter"
          :drag-over-func="onDragOver"
          :drag-leave-func="onDragLeave"
          :drop-func="onDrop"
        >
          <template #day="{ scope: { timestamp } }">
            <template v-for="holiday in getHolidays(timestamp.date)" :key="holiday.id">
              <div class="holiday rounded-border">
                <div class="title q-calendar__ellipsis">
                  {{ holiday.title }}
                  <q-tooltip>{{ holiday.title }}</q-tooltip>
                </div>
              </div>
            </template>
            <template v-for="event in getEventsSorted(timestamp.date)" :key="event.id">
              <div class="game-event rounded-border"
                  :class="{deleted: event.deleted, fixed: event.fixedMatch,
                    dragging: isDragging && dragEventId != event.id}"
                  draggable="true"
                  @dragstart="onDragStart($event, event)">
                <div class="title q-calendar__ellipsis">
                  {{ event.title }}
                  <q-tooltip>{{ formatDateRange(event) }}</q-tooltip>
                </div>
                <q-btn v-if="!event.deleted"
                  flat class="absolute-right" size="xs" padding="xs" color="tba-blue" icon="close"
                  @click="eventDelete(event)" />
                <q-btn v-if="event.deleted"
                  flat class="absolute-right" size="xs" padding="xs" color="grey" icon="refresh"
                  @click="eventDelete(event, true)" />
              </div>
            </template>
          </template>
          <template #day-body="{ scope }">
            <template v-for="(event, index) in getEventsSorted(scope.timestamp.date)">
              <q-badge
                v-if="event.time"
                :key="index"
                size="sm"
                class="game-event game-weekly q-pa-xs justify-center full-width"
                :class="{deleted: event.deleted, fixed: event.fixedMatch,
                  dragging: isDragging && dragEventId != event.id}"
                :style="weekDayStyles(event, 'body', scope.timeStartPos, scope.timeDurationHeight)"
                draggable="true"
                @dragstart="onDragStart($event, event)"
              >
                <div class="title q-calendar__ellipsis">
                  {{ event.title }}
                  <q-tooltip>{{ formatDateRange(event) }}</q-tooltip>
                </div>
                <span class="ellipsis">{{ formatDateRange(event) }}</span>
                <q-btn v-if="!event.deleted"
                  flat class="absolute-top-right" size="xs" padding="xs" color="tba-blue" icon="close"
                  @click="eventDelete(event)" />
                <q-btn v-if="event.deleted"
                  flat class="absolute-top-right" size="xs" padding="xs" color="grey" icon="refresh"
                  @click="eventDelete(event, true)" />
              </q-badge>
            </template>
          </template>
        </q-calendar>
      </div>
    </div>
    <GameTally :events="events" />
    <nav id="navigation">
      <button id="back" @click.prevent="goBack">Back</button>
      <button id="export" class="green" @click.prevent="exportSchedule">Export</button>
    </nav>
  </section>
</template>

<script>
/* eslint-disable */

import calendarMixin from '@/mixins/calendarMixin.js';
import dragDropMixin from '@/mixins/dragDropMixin.js';
import GameTally from './GameTally.vue';

import { DateTime } from 'luxon';
import { QCalendar } from '@quasar/quasar-ui-qcalendar/src/index.js';

import '@quasar/quasar-ui-qcalendar/src/QCalendarVariables.sass';
import '@quasar/quasar-ui-qcalendar/src/QCalendarTransitions.sass';
import '@quasar/quasar-ui-qcalendar/src/QCalendarMonth.sass';
import schedulerService from '@/services/SchedulerService';
import { useSSRContext } from 'vue';

export default {
  name: 'SchedulerFinalize',
  components: {GameTally, QCalendar},
  mixins: [calendarMixin, dragDropMixin],
  data: () => ({
    selectedDate: DateTime.now().toFormat('yyyy-MM-dd'),
    selectedMode: 'month',
    events: [],
    holidays: []
  }),
  computed: {
    eventsSelectedDate() {
      let regex = /\-[0-9]{2}$/;
      return this.events.filter(
          event => event.date.replace(regex, '') == this.selectedDate.replace(regex, ''));
    },
    monthAndYear() {
      return DateTime.fromISO(this.selectedDate).toFormat('LLLL yyyy');
    }
  },
  mounted() {
    let holidays = this.$store.state.holidays,
      gameSchedule = this.$store.state.gameSchedule,
      gameScheduleFinal = this.$store.state.gameScheduleFinal;

    this.selectedDate = gameSchedule.length ?
      gameSchedule[0].gameTime.toFormat('yyyy-MM-dd') : this.selectedDate;

    for (let [index, holiday] of Object.entries(holidays)) {
      this.holidays.push({
        id: index + 1,
        title: holiday.name,
        date: holiday.date.toFormat('yyyy-MM-dd'),
        bgcolor: 'red'
      });
    }

    if (!gameScheduleFinal.length) {
      for (let [index, gameDate] of Object.entries(gameSchedule)) {
        this.createEvent({id: index + 1, ...gameDate});
      }
    } else {
      this.events = [...gameScheduleFinal];
    }
  },
  methods: {
    getEvents(date, eventId = null) {
      return eventId ?
        this.eventsSelectedDate.filter(event => event.date == date && event.id != eventId) :
        this.eventsSelectedDate.filter(event => event.date == date);
    },
    getEventsSorted(date) {
      return this.getEvents(date).sort(
        (a, b) => 
            DateTime.fromSQL(`${a.date} ${a.time}`) > DateTime.fromSQL(`${b.date} ${b.time}`) ?
          1 : -1 );
    },
    createEvent(gameDate) {
      this.events.push({
        id: gameDate.id,
        title: this.formatTeamMatch([gameDate.homeTeam, gameDate.awayTeam]),
        fixedMatch: gameDate.fixedMatch,
        homeTeam: gameDate.homeTeam,
        awayTeam: gameDate.awayTeam,
        date: gameDate.gameTime.toFormat('yyyy-MM-dd'),
        time: gameDate.gameTime.toFormat('HH:mm'),
        duration: 90,
        deleted: false,
        bgcolor: 'blue'
      });
    },
    eventDelete(event, restore = false) {
      let eventIndex = this.events.map(item => item.id).indexOf(event.id);

      this.events[eventIndex].deleted = !restore;

      this.saveSchedule();
    },
    gotoDate(dateString) {
      this.selectedDate = dateString;
    },
    goBack() {
      this.$router.push({path: '/pattern'});
    },
    goPrev() {
      this.$refs.calendar.prev();
    },
    goNext() {
      this.$refs.calendar.next();
    },
    saveSchedule() {
      let gameScheduleFinal = this.events.map(
        game => { return { ...game, gameTime: DateTime.fromSQL(`${game.date} ${game.time}`) }; });

      this.$store.commit('setGameScheduleFinal', gameScheduleFinal)

      return gameScheduleFinal;
    },
    exportSchedule() {
      let teams = this.$store.state.teams.filter(team => team.enabled),
        gameScheduleFinal = this.saveSchedule();

      this.$store.commit('setGameScheduleFinal', gameScheduleFinal);

      schedulerService.downloadSchedules(teams, gameScheduleFinal.filter(game => !game.deleted));
    },
    onDrop(e, type, scope) {
      let itemId = parseInt(e.dataTransfer.getData('ID')),
        itemIndex = this.events.map(item => parseInt(item.id)).indexOf(itemId),
        dateTime = DateTime.fromSQL(`${scope.timestamp.date} ${scope.timestamp.time}`),
        existingEvents = this.isOverlapped(itemId, dateTime, true);

      if (!existingEvents.length) {
        this.events[itemIndex] =
          {...this.events[itemIndex], ...{
            date: scope.timestamp.date,
            time: scope.timestamp.time == '00:00' ?
              this.events[itemIndex].time : scope.timestamp.time
            }};
      } else {
        let existingEvent = {...existingEvents.shift()},
          existingEventIndex = _.findIndex(this.events, {id: existingEvent.id});

        this.events[existingEventIndex] = {
          ...existingEvent,
          ...{date: this.events[itemIndex].date, time: this.events[itemIndex].time}
        };
        this.events[itemIndex] =  
          {...this.events[itemIndex], ...{date: existingEvent.date, time: existingEvent.time}};
      }

      this.isDragging = false;
    },
    onIntervalClass({scope}) {
      return {allowed: true, droppable: scope.droppable == true};
    },
    weekDayStyles(event, type, timeStartPos, timeDurationHeight) {
      let style = {'align-items': 'flex-start'};

      if (timeStartPos) {
        style.top = `${timeStartPos(event.time)}px`;
      }
      if (timeDurationHeight) {
        style.height = `${timeDurationHeight(event.duration)}px`;
      }

      return style;
    },
  }
}
</script>

<style lang="scss" scoped>
#calendar {
  width: 100%;

  > div:first-of-type {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;

    > div {
      width: 100%;
      max-width: 1121px;
    }
  }
}

#calendar-actions {
  width: 100%;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
  display: flex;

  > div {
    align-items: center;
    justify-content: center;
    display: flex;

    > *:not(:last-child) {
      margin-right: 20px;
    }

    > h2 {
      padding: 0;
      margin: 0;
    }

    > button {
      &[id^=month] {
        width: 6px;
        height: 12px;
        padding: 0;
      }

      &#month-previous {
        background: url('@/assets/icon-left.svg') no-repeat;
        background-size: 100%;
      }

      &#month-next {
        background: url('@/assets/icon-right.svg') no-repeat;
        background-size: 100%;
      }
    }
  }
}

.holiday,
.game-event {
  border-radius: 5px;
  position: relative;
  font-size: 12px;
  font-weight: normal;
  width: 95%;
  height: 31px;
  margin: 1px 0 0 0;
  box-sizing: border-box;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: move;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  position: relative;

  &.dragging {
    z-index: -1;
  }

  button span {
    color: $blue;
  }
}

.game-weekly {
  padding-top: 8px !important;
  flex-direction: column;
  position: absolute;

  div.title {
    font-weight: bold;
  }
}

.holiday {
  background-color: #F26522;
  font-style: italic;
  color: white;
  padding: 1px 10px;
  width: 100%;
}

.game-event {
  background-color: rgba(0, 126, 218, 0.1);
  color: #002F5B;
  padding: 1px 10px 1px 21px;

  &::before {
    border: none;
    border-radius: 50%;
    background-color: $blue;
    width: 7px;
    height: 7px;
    top: 12px;
    left: 7px;
    margin: auto 0;
    content: ' ';
    position: absolute;
  }

  &.fixed {
    background-color: rgba(242, 101, 34, 0.1);

    &::before {
      background-color: $orange;
    }
  }

  &.deleted {
    background-color: rgba(213, 213, 213, 0.5);
    text-decoration: line-through;
    color: #002F5B;

    &::before {
      background-color: rgba(0, 47, 91, 0.5);
    }
  }
}
</style>