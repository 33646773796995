import _ from 'lodash';
import JSZip from 'jszip';
import { DateTime, Info } from 'luxon';

class SchedulerService {
  /**
   * Create an array of dates and times for specified range
   * @param {String} Start Date
   * @param {String} End Date
   * @param {Object} Weekly Schedule
   * @returns An array of {@code DateTime} objects for scheduling games with.
   */
  createGameDates(startDate, endDate, Schedule) {
    let result = [], seasonWeekNumber = 1, currentDate = DateTime.fromJSDate(startDate),
      _endDate = DateTime.fromJSDate(endDate);

    while (currentDate <= _endDate.plus({weeks: 1})) {
      let currentWeek = DateTime.fromObject(
        {weekNumber: currentDate.weekNumber, weekYear: currentDate.weekYear});

      for (let [weekDay, gameTimes] of Object.entries(Schedule)) {
        let weekDayNumber = Info.weekdays().indexOf(weekDay) + 1;
        if (weekDayNumber < currentDate.weekday) continue;

        for (let gameTime of gameTimes) {
          let gameDateTime = this.createGameDayObject(currentWeek, weekDayNumber, gameTime);

          if (gameDateTime.date.startOf('day') <= _endDate.startOf('day')) {
            if (!(seasonWeekNumber % 2) && gameTime.fixedMatch && gameTime.biweekly) continue;

            result.push(gameDateTime);
          }
        }
      }

      currentDate = currentWeek.plus({weeks: 1});
      seasonWeekNumber++;
    }

    return result.sort();
  }

  /**
   * Create game day object for creating a new {@code DateTime} object.
   * @param {DateTime} Current week 
   * @param {Number} Week day
   * @param {Object} Game time 
   * @returns An object containing the game time.
   */
  createGameDayObject(currentWeek, weekDay, gameTime) {
    let dateObject =
      {weekYear: currentWeek.weekYear, weekNumber: currentWeek.weekNumber, weekDay: weekDay};

    return {
      fixedMatch: gameTime.fixedMatch,
      date: DateTime.fromObject({...dateObject, ...{hour: gameTime.hour, minute: gameTime.minute}}),
      selected: true,
      holiday: false,
    };
  }

  /**
   * Match the opposite vertices of a polygon.
   * @param {Array} vertices 
   * @param {Number} n
   * @param {Number} round
   * @returns An array of matched vertices.
   */
  matchVertices(vertices, n, round) {
    let result = [];

    for (let i = 0; i < parseInt(Math.floor(n / 2)) - 1; i++) {
      let match = [vertices[i], vertices[vertices.length - 1 - i]];

      if (!(round % 2))  _.reverse(match);
      result.push(match);
    }

    return result;
  }

  /**
   * Rotate the teams and then match them against one another using the round robin algorithm.
   * @param {Array} teams 
   * @param {Number} rotation 
   * @param {Number} round 
   * @returns An array of matches for the current round robin iteration.
   */
  createMatches(teams, rotation, round) {
    let result = [], vertices = teams.slice();

    if (!(teams.length % 2)) {
      let first, last = vertices.pop(), reverse = !(round % 2) ? !(rotation % 2) : rotation % 2;

      this.rotate(vertices, rotation);

      first = vertices.shift();
      result.push(reverse ? [first, last] : [last, first]);
    } else {
      this.rotate(vertices, rotation);
      vertices.shift();
    }

    result = [...result, ...this.matchVertices(vertices, teams.length, round)];

    return result;
  }

  /**
   * Assign created matches to pre-determined rink dates.
   * @param {Array} gameDates 
   * @param {Array} matches 
   * @param {Array} fixedTeams
   * @param {Number} randomLevel
   * @returns An array of assigned games with the supplied list of dates.
   */
  assignGameDates(gameDates, matches, fixedTeams, randomLevel) {
    let fixedTeamIndex = 0, assignments = [], result = [], gameDays = {};

    for (let gameTime of gameDates) {
      let gameDay = gameTime.date.toFormat('yyyy-MM-dd');
      if (typeof gameDays[gameDay] == 'undefined') gameDays[gameDay] = [];

      if (gameTime.fixedMatch) {
        if (fixedTeams.length) {
          let fixedTeam = fixedTeams[fixedTeamIndex];
          fixedTeamIndex = ++fixedTeamIndex % fixedTeams.length;

          /*assignments.push([fixedTeam, fixedTeam, `"${gameTime.date.toFormat('ffff')}"`]);
          result.push(assignments.slice(-1).join(','));*/
          result.push({
            homeTeam: fixedTeam,
            awayTeam: fixedTeam,
            gameTime: gameTime.date,
            fixedMatch: true
          });
        }

        continue;
      }

      for (let [i, match] of Object.entries(matches)) {
        let homeTeam = match[0], awayTeam = match[1],
          lookback = assignments.slice(-(Math.max(1, randomLevel - 1))).shift() || [];

        if (lookback.indexOf(homeTeam) != -1 || lookback.indexOf(awayTeam) != -1) continue;
        if (gameDays[gameDay].indexOf(homeTeam) != -1) continue;
        if (gameDays[gameDay].indexOf(awayTeam) != -1) continue;

        gameDays[gameDay] = [...gameDays[gameDay], ...[homeTeam, awayTeam]];

        /*assignments.push([homeTeam, awayTeam, `"${gameTime.date.toFormat('ffff')}"`]);
        result.push(assignments.slice(-1).join(','));*/
        result.push({
          homeTeam: homeTeam,
          awayTeam: awayTeam,
          gameTime: gameTime.date,
          fixedMatch: false
        });

        matches.splice(parseInt(i), 1);

        break;
      }
    }

    return result;
  }

  /**
   * Create the game schedule.
   * @param {Array} gameDates Game dates.
   * @param {Array} teams Team list
   * @param {Number} randomLevel Randomness
   */
  createSchedule(gameDates, teams, randomLevel = 1) {
    let matches = [],
      fixedTeams = teams.filter(team => team.fixed).map(team => team.name),
      matchedTeams = teams.filter(team => !team.fixed).map(team => team.name),
      matchedGameDates = gameDates.filter(gameDate => !gameDate.fixedMatch),
      gamesPerRound = (teams.length ** 2 - teams.length) / 2,
      totalMatchedRounds = Math.floor(matchedGameDates.length / gamesPerRound),
      remainingMatchedGames = gameDates.length - gamesPerRound * totalMatchedRounds;

    for (let i = 0; i < totalMatchedRounds; i++) {
      for (let j = 1; j < matchedTeams.length + (matchedTeams.length % 2); j++) {
        let newMatches = this.createMatches(matchedTeams, j, i + 1);

        matches = [...matches, ...randomLevel >= 2 ? _.shuffle(newMatches) : newMatches];
      }
    }

    let shuffledTeams = randomLevel >= 2 ? _.shuffle(matchedTeams.slice()) : matchedTeams;
    for (let j = 1; j < parseInt(remainingMatchedGames * 2 / shuffledTeams.length) + 1; j++) {
      let newMatches = this.createMatches(shuffledTeams, j, 1);

      matches = [...matches, ...randomLevel >= 2 ? _.shuffle(newMatches) : newMatches];
    }

    if (randomLevel > 2) {
      matches = _.shuffle(matches);
    }

    //let gameAssignments = this.assignGameDates(gameDates, matches, fixedTeams, randomLevel);
    //this.downloadSchedules(teams, gameAssignments);

    return this.assignGameDates(gameDates, matches, fixedTeams, randomLevel);
  }

  /**
   * Rotate an array of data to the left by a specified number of times.
   * @param {Array} arr Array to rotate
   * @param {Number} rotation Number of rotations
   */
  rotate(arr, rotation) {
    for (let i = 0; i < rotation; i++) {
      arr.push(arr.shift());
    }
  }

  /**
   * Download the generated game schedules.
   * @param {Array} Teams
   * @param {Array} Schedule
   */
  downloadSchedules(teams, schedule) {
    let headers = ['Home', 'Away', 'Date/Time'],
      scheduleData = [headers.join(','), ...schedule.map(game => {
        return [game.homeTeam, game.awayTeam, `"${game.gameTime.toFormat('ffff')}"`].join(',')
      })],
      scheduleListFiles = {
        'schedule.csv': scheduleData.join('\n'), 
        ...teams.reduce((accumulator, team) => {
          let teamSchedule = scheduleData.slice().filter(
            (row, index) => !index || row.indexOf(team.name) != -1).join('\n');

          return {
            ...accumulator,
            [`${team.name.toLowerCase()}.csv`]: teamSchedule
          };
        }, {})
      };

    this.createDownload('schedule.zip', scheduleListFiles);
  }

  /**
   * Create a downloadable a file from a text string.
   * @param {String} Filename 
   * @param {String} Schedule lists
   */
  createDownload(filename, scheduleListFiles) {
    let zipFile = new JSZip(), zipFolder = zipFile.folder('schedule');

    for (let [filename, data] of Object.entries(scheduleListFiles)) {
      zipFolder.file(filename, data);
    }

    zipFile.generateAsync({type: 'base64'}).then((zipFileData) => {
      let element = document.createElement('a');

      element.setAttribute(
        'href', `data:application/zip;base64,${encodeURIComponent(zipFileData)}`);
      element.setAttribute('download', filename);

      element.style.display = 'none';

      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    });
  }
}

let schedulerService = new SchedulerService();

export default schedulerService;