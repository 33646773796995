<template>
  <section id="schedule-progress">
    <div v-for="(step, index) in steps" v-bind:key="index" :class="stepClass(index)">
      <div :class="stepClass(index)">
        <span v-if="currentStep <= index + 1">{{ index + 1 }}</span>
      </div>
      <span>{{ step }}</span>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SchedulerProgress',
  data: () => ({
    steps: ['Season', 'Teams',  'Schedule', 'Holidays', 'Review', 'Pattern', 'Final']
  }),
  computed: {
    currentStep() {
      switch (this.$route.fullPath) {
        default:
        case '/':
        case '/season':
          return 1;
        case '/teams':
          return 2;
        case '/schedule':
          return 3;
        case '/holidays':
          return 4;
        case '/review':
          return 5;
        case '/pattern':
          return 6;
        case '/finalize':
          return 7;
      }
    }
  },
  methods: {
    stepClass(index) {
      let step = index + 1;
      if (this.currentStep == step) return 'active';
      else if (this.currentStep < step) return 'incomplete';
      return 'complete';
    }
  },
}
</script>

<style lang="scss">
#schedule-progress {
  user-select: none;
  align-items: flex-start;
  justify-content: center;
  display: inline-flex;

  > div {
    width: 35%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    position: relative;

    &:not(:last-of-type) {
      margin-right: 90px;

      > div {
        &::before {
          border: 3px solid #D5D5D580;
          width: 70px;
          height: 2px;
          top: 16px;
          left: 45px;
          position: absolute;
          content: ' ',
        }

        &.complete {
          &::before {
            border: 3px solid $blue;
            width: 70px;
            height: 2px;
            top: 16px;
            left: 45px;
            position: absolute;
            content: ' ',
          }
        }

        &.active {
          &::after {
            border: 3px solid $blue;
            width: 20px;
            height: 2px;
            top: 16px;
            left: 45px;
            position: absolute;
            content: ' ',
          }
        }
      }
    }

    > div {
      border-radius: 50%;
      background-color: $blue;
      width: 35px;
      height: 35px;
      align-items: center;
      justify-content: center;
      display: flex;
      position: relative;

      &.complete {
        background: $blue url('@/assets/check.svg') no-repeat;
        background-position: center;

        > span {
          display: none;
        }
      }

      &.active {
        background-color: $blue;
        font-weight: bold;
        color: white;

        > span {
          font-weight: bold;
          color: white;
        }
      }

      &.incomplete {
        border: 2px solid #D5D5D580;
        border-radius: 50%;
        background-color: transparent;
        font-weight: normal;
        color: #D5D5D580;
      }

      > span {
        font-size: 18px;
        color: #D5D5D580;
      }
    }

    > span {
      color: white;
      top: 44px;
      white-space: nowrap;
      position: absolute;
    }

    &.active:not(:last-of-type) {
      font-style: italic;
    }

    &.active:last-of-type {
      font-style: normal;
    }

    &:not(.active) {
      > span {
        opacity: 0.5;
      }
    }
  }
}
</style>