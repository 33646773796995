import _ from 'lodash';
import { createApp } from 'vue';
import { createRouter, createWebHashHistory } from 'vue-router';
import { Quasar } from 'quasar';

import App from './App.vue';
import HockeySeason from './components/HockeySeason.vue';
import HockeyTeams from './components/HockeyTeams.vue';
import HockeySchedule from './components/HockeySchedule.vue';
import HockeyHolidays from './components/HockeyHolidays.vue';
import SchedulerReview from './components/SchedulerReview.vue';
import SchedulerPattern from './components/SchedulerPattern.vue';
import SchedulerFinalize from './components/SchedulerFinalize.vue';
import store from './store';

import '@quasar/extras/material-icons/material-icons.css';
import 'quasar/src/css/index.sass';

const routes = [
  { name: 'HockeySeason', path: '/', component: HockeySeason },
  { path: '/season', component: HockeySeason },
  { path: '/teams', component: HockeyTeams },
  { path: '/schedule', component: HockeySchedule },
  { path: '/holidays', component: HockeyHolidays },
  { path: '/review', component: SchedulerReview },
  { path: '/pattern', component: SchedulerPattern },
  { path: '/finalize', component: SchedulerFinalize }
];

let lastRoute = store.state.currentRoute;

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior() {
    return { top: 0 };
  },
  routes
});

router.beforeEach((to, _from, next) => {
  let stepIndex = _.findIndex(routes, (route) => { return route.path == to.fullPath; });

  /*if (from == START_LOCATION && ['/', '/season'].indexOf(to.path) == -1) {
    document.location.href = '/';
  }*/

  store.commit('setCurrentStep', stepIndex);
  store.commit('setCurrentRoute', to.fullPath);

  next();
});

const app = createApp(App);

app.use(Quasar);
app.use(store);
app.use(router);

app.mount('#app');

if (lastRoute) {
  router.push({path: lastRoute});
}