/* eslint-disable */

export default {
  name: 'DragDropMixin',
  data: () => ({
    dragEventId: null,
    isDragging: false
  }),
  methods: {
    onDragStart(event, item) {
      event.dataTransfer.dropEffect = 'copy';
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('ID', item.id);
      this.dragEventId = item.id;
      this.isDragging = true;
    },
    onDragEnter(e, type, scope) {
      e.preventDefault();
      return true;
    },
    onDragOver(e, type, scope) {
      e.preventDefault();
      return true;
    },
    onDragLeave(e, type, scope) {
      return false;
    },
    onDrop(e, type, scope) {
      let itemId = parseInt(e.dataTransfer.getData('ID'), 10),
        itemIndex = this.events.map(item => item.id).indexOf(itemId);

      console.log(`id: ${itemId}, date: ${scope.timestamp.date}, time: ${scope.timestamp.time}`);

      this.events[itemIndex] =
        {...this.events[itemIndex], ...{date: scope.timestamp.date, time: scope.timestamp.time}};
      this.isDragging = false;
    },
  }
};