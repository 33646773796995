<template>
  <div class="item-checkbox" :class="checkboxStyle()" @click.prevent="updateValue">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ItemCheckbox',
  props: {
    modelValue: {
      type: Boolean
    },
    class: {
      type: String
    }
  },
  emits: ['update:modelValue'],
  data: () => ({
    selected: false
  }),
  mounted() {
    this.selected = this.modelValue;
  },
  methods: {
    checkboxStyle() {
      let classList = this.class ? this.class.split(/\s/).reduce(
        (accumulator, item) => { return {...accumulator, [item]: true}; }, {}) : {};
      return {selected: this.selected, ...classList};
    },
    updateValue() {
      this.selected = !this.selected;
      this.$emit('update:modelValue', this.selected);
    }
  }
}
</script>

<style lang="scss">
div.item-checkbox {
  border: 2px solid $grey;
  border-radius: 5px;
  background: white url('@/assets/add.svg') no-repeat;
  background-size: 41px;
  background-position: right 20px center;
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  color: $darkerBlue;
  height: 60px;
  padding-right: 65px;
  padding-left: 20px;
  align-items: center;
  display: inline-flex;
  user-select: none;
  cursor: pointer;

  &.selected {
    border: 2px solid $blue;
    background: rgba(0, 126, 218, 0.1) url('@/assets/checked.svg') no-repeat;
    background-size: 41px;
    background-position: right 20px center;

    &.orange {
      border: 2px solid $orange;
      background: rgba(242, 101, 34, 0.1) url('@/assets/checked-orange.svg') no-repeat;
      background-size: 41px;
      background-position: right 20px center;
    }
  }
}
</style>